


































import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PageHeader } from "@/components/al/pageHeader";
import ProjectCommentsList from "@/views/project/ProjectCommentsList.vue";
@Component({
    components: {
        ProjectCommentsList,
        "al-page-header": PageHeader,
    },
    data() {
        return {
            commentsList: [],
        };
    },
})
export default class ProjectCommentsView extends Vue {
    @Prop({ type: [String, Number], required: true, default: () => "" })
    pid: any;

    @Getter("getUser") getUser: any; //获取store中的user的方法

    get user() {
        return this.getUser();
    }
    get project_id() {
        return Number(this.pid) ?? Number(this.user.project_id);
    }

    actived: number = 0; //0是原文,1是英文,2是中文
    /**设置项目语言版本导航actived */
    setNavActived(index: number) {
        this.actived = index;
    }
    /**项目语言版本导航点击事件 */
    handleNavItem(index: number | string) {
        this.setNavActived(Number(index));
    }

    /**获取评论列表 */
    getComments() {
        const getOpt = this.$api.apiList.getProjectComments;
        const opt = getOpt(this.project_id);
        // const opt = getOpt(2469);

        this.$axios({
            method: opt.method,
            url: opt.url,
            headers: opt.headers,
        }).then((res: any) => {
            this.$data.commentsList = res.data;
            console.log(res.data);
        });
    }

    mounted() {
        this.getComments();
    }
}
