



































import { Vue, Component, Prop } from "vue-property-decorator";
import { Empty } from "element-ui";

@Component({
    components: { "el-empty": Empty },
    name: "CommentsList",
})
export default class ProjectCommentsList extends Vue {
    @Prop({ type: Array, required: true, default: () => [] })
    comments: any;

    @Prop({ type: [String, Number], required: false, default: () => 0 })
    lang: any;

    
}
